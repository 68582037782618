import { userInfo as localUserInfo } from './globalData';
import { router } from '../router';
import { showConfirmDialog } from 'vant';
import 'vant/es/dialog/style';

/**
 * 判断用户是否登录
 */
export const isLogin = () => {
    if (!localUserInfo.token) {
        showConfirmDialog({
            title: '请先登录',
        })
            .then(() => {
                return router.push('/login');
            })
            .catch(() => {
                return router.push('/login');
            });
    }
    return true;
};

export function randomNum(minNum = 1000, maxNum = 9999) {
    switch (arguments.length) {
        case 1:
            return Math.floor(Math.random() * minNum + 1).toString();
        case 2:
            return Math.floor(
                Math.random() * (maxNum - minNum + 1) + minNum
            ).toString();
        default:
            return '0';
    }
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0 || !time) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if (typeof time === 'string') {
            if (/^[0-9]+$/.test(time)) {
                // support "1548221490638"
                time = parseInt(time);
            } else {
                time = time.replace(new RegExp(/-/gm), '/');
            }
        }

        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        return value.toString().padStart(2, '0');
    });
    return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (!time) {
        return null;
    }
    time = time * 1000;
    const d = new Date(time);
    const now = Date.now();

    const diff = (now - d.getTime()) / 1000;

    if (diff < 30) {
        return '刚刚';
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前';
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前';
    } else if (diff < 3600 * 24 * 2) {
        return '1天前';
    }
    if (option) {
        return parseTime(time, option);
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        );
    }
}

/**
 * 展开菜单
 * @param menu
 * @returns
 */
export function expandMenu(menu) {
    const res = {};
    const fn = (menu) => {
        res[menu.url] = { url: menu.url, name: menu.name, type: menu.type };
        if (menu.children && menu.children.length > 0) {
            menu.children.forEach((item) => {
                fn(item);
            });
        }
    };
    fn(menu);
    return res;
}

export function isNullValue(str) {
    if (str && str != 'null' && str != 'undefined') {
        return true;
    }
    return false;
}

// 获取当前域名的泛域名
export function getDomain() {
    const reg = new RegExp(
        /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/
    ); // 验证ip地址
    if (reg.test(document.domain)) {
        return document.domain;
    }
    let host = location.hostname;
    const temp = host.split('.');
    if (temp.length >= 3) {
        host = '.' + temp[temp.length - 2] + '.' + temp[temp.length - 1];
    }
    return host;
}

// 时间戳转换成日期格式
export function timestampToTime(timestamp, type = 'YYYY-MM-DD HH:mm:ss') {
    if (!timestamp) {
        return '-';
    }

    if (timestamp.toString().length == 10) {
        timestamp = timestamp * 1000;
    }

    const date = new Date(timestamp);
    const Y = date.getFullYear() + '-';
    const M =
        (date.getMonth() + 1 < 10 ? date.getMonth() + 1 : date.getMonth() + 1) +
        '-';
    const D = date.getDate() + ' ';
    const h = date.getHours() + ':';
    const m = date.getMinutes() + ':';
    const s = date.getSeconds();

    if (type == 'YYYY-MM-DD') {
        return Y + M + D;
    } else if (type == 'HH:mm:ss') {
        return h + m + s;
    } else {
        return Y + M + D + h + m + s;
    }
}

export function setFlag(name = '', value = '') {
    return localStorage.setItem(name, value);
}

export function getFlag(name = '') {
    return localStorage.getItem(name);
}
// zxb
// 时间戳转换成日期格式
export function DateformatTime(data) {
    //data时间戳，type返回的类型默认Y,可传参Y和H
    let time = new Date(data * 1000);
    let Y = time.getFullYear();
    let Mon = time.getMonth() + 1;
    let Day = time.getDate();
    let H = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
    let Min = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    let S = time.getSeconds();
    //自定义选择想要返回的类型
    // if (type === "Y") {   //返回年月日2020-10-10
    // 	return `${Y}年${Mon}月${Day}日`
    // } else if (type === "H") {  //返回时分秒20：10：10
    // 	return `${H}:${Min}:${S}`
    // } else {  //返回年月日时分秒2020-10-10 10:26:38
    // 	// return `${Y}-${Mon}-${Day} ${H}:${Min}:${S}`

    // }
    return `${Y}-${Mon}-${Day} ${H}:${Min}:${S}`;
}
// 去掉字符串中的所有html标记
export function delHtmlTag(str) {
    return str.replace(/<[^>]+>/g, "");//去掉所有的html标记
}

// 时间戳转换成日期格式
export function formatDate(data) {
    //data时间戳，type返回的类型默认Y,可传参Y和H
    let time = new Date(data * 1000);
    let Y = time.getFullYear();
    let Mon = time.getMonth() + 1;
    let Day = time.getDate();
 
    return `${Y}.${Mon}.${Day}`;
}